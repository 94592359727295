<template>
  <div class="zy-multiple-pie">
    <div class="jxyh-flex-v" style="justify-content: space-around;">
      <div v-for="(item, index) in list" :key="index">
        <zy-chart-ring class="chart-pie" :color="color" :data="item.pieList" :auto="[75,94]">
          <div class="chart-pie-inner">
            <div style="font-size: 20px">{{ item.value }}</div>
            <div>{{ item.bottom }}</div>
          </div>
        </zy-chart-ring>
        <div class="jxyh-flex-v legend">
          <div class="legend-item" v-for="(i, index) in item.pieList" :key="`tips_${index}`">
            <div class="legend-item-line" :style="{ background: color[index] }"></div>
            <div>
              <span style="color: #ffffff;">{{ i.name }}</span>
              <br>{{ i.value }}条
              <span style="margin-left: 10px;">{{ i.rate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import components from '@/view/jxyh/components'
export default {
  ...components(['ZyChartRing'], {}),
  props: {
    color: {
      type: Array,
      default: () => ['#007FF4', '#FC4A66', '#FFB822', '#62FF85', '#19FBFF']
    },
    list: {
      type: Array,
      default: () => []
    },
  },
}
</script>

<style lang="less" scoped>
.zy-multiple-pie {
  width: 534px;
  .chart-pie {
    margin-top: 20px;
    height: 160px;
    width: 200px;
    &-inner {
      text-align: center;
      font-size: 12px;
      line-height: 20px;
      font-weight: bold;
    }
  }
  .legend {
    flex-wrap: wrap;
    &-item {
      margin-top: 16px;
      margin-right: 20px;
      font-size: 12px;
      line-height: 18px;
      flex-shrink: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #B1B1B1;
      &-line{
        width: 4px;
        height: 24px;
        border-radius: 2px;
        margin-right: 10px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
