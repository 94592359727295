<template>
  <zy-layout class="zy-layout-quality">
    <!-- 左边布局 -->
    <zy-layout-column animation="left">
      <zy-title>质量巡检</zy-title>
      <Polling
        :list="sgList"
        :color="color"
      ></Polling>
      <zy-scroll-table
        :limitMoveNum="6"
        :data="pollingList"
        :height="200"
        style="margin-top: 24px"
      >
        <zy-scroll-table-column
          label="检查批次名称"
          prop="inspectionBatchName"
          width="180px"
        >
        </zy-scroll-table-column>
        <zy-scroll-table-column
          label="类型"
          prop="checkTypeStr"
          width="126px"
        ></zy-scroll-table-column>
        <zy-scroll-table-column
          label="负责人"
          prop="principalName"
          width="110px"
        ></zy-scroll-table-column>
        <zy-scroll-table-column
          label="计划开始时间"
          width="117px"
          prop="createTime"
        ></zy-scroll-table-column>
      </zy-scroll-table>
      <zy-title
        @more="showDialog('measure')"
        class="jxyh-margin"
        >实测实量</zy-title
      >
      <measure :list="measureList"></measure>
    </zy-layout-column>
    <!-- 中间布局 -->
    <zy-layout-column animation="center">
      <zy-title>质量亮点</zy-title>
      <zy-image-text
        style="margin-top: 14px"
        :height="230"
        :list="highLightList"
      ></zy-image-text>
      <zy-title
        @more="showDialog('locale')"
        class="jxyh-margin"
        >现场验收</zy-title
      >
      <zy-scroll-table
        :data="localeList"
        :height="280"
        :rowHeight="71"
        style="margin-top: 10px"
      >
        <zy-scroll-table-column
          label="部位"
          prop="positionStr"
          width="174px"
        >
        </zy-scroll-table-column>
        <zy-scroll-table-column
          label="工序验收项"
          prop="processAcceptanceItemName"
          width="170px"
        ></zy-scroll-table-column>
        <zy-scroll-table-column
          label="报验日期"
          align="center"
          prop="createTime"
          width="100px"
        ></zy-scroll-table-column>
        <zy-scroll-table-column
          label="实际验收日期"
          align="center"
          prop="endTime"
          width="110px"
        ></zy-scroll-table-column>
        <zy-scroll-table-column
          label="验收状态"
          prop="status"
          width="100px"
        >
          <template slot-scope="{ row }">
            <span
              v-if="row.status == '验收不通过'"
              style="color: #fc4a66"
              >{{ row.status }}</span
            >
            <span
              v-else-if="row.status == '待验收'"
              style="color: #ffb822"
              >{{ row.status }}</span
            >
            <span
              v-else
              style="color: #63e881"
              >{{ row.status }}</span
            >
          </template>
        </zy-scroll-table-column>
        <zy-scroll-table-column
          label="附件"
          align="center"
          prop="imgUrl"
          width="108px"
        >
          <template slot-scope="{ row }">
            <div style="display: flex">
              <!-- <el-image class="file-img" :src="row.imgUrl" :preview-src-list="[row.imgUrl]">
                <div slot="placeholder" class="image-slot">
                  <i class="el-icon-loading"></i>加载中
                </div>
              </el-image> -->
              <zy-image
                class="file-img"
                :src="row.imgUrl"
                preview
              ></zy-image>
            </div>
          </template>
        </zy-scroll-table-column>
      </zy-scroll-table>
    </zy-layout-column>
    <!-- 右边布局 -->
    <zy-layout-column animation="right">
      <zy-title @more="showDialog('danger')">检查问题</zy-title>
      <zy-scroll-table
        :data="dangerList"
        class="auto-height"
      >
        <zy-scroll-table-column
          label="检查项"
          prop="checkitemname"
          width="133px"
        >
        </zy-scroll-table-column>
        <zy-scroll-table-column
          label="部位"
          prop="position"
          width="140px"
        ></zy-scroll-table-column>
        <zy-scroll-table-column
          label="整改期限"
          prop="rectificationdate"
          width="100px"
        ></zy-scroll-table-column>
        <zy-scroll-table-column
          label="问题等级"
          prop="problemlevel"
          width="80px"
        >
          <template slot-scope="{ row }">
            <span
              v-if="row.problemlevel == '一般'"
              style="color: #63e881"
              >{{ row.problemlevel }}</span
            >
            <span
              v-else
              style="color: #fc4a66"
              >{{ row.problemlevel }}</span
            >
          </template>
        </zy-scroll-table-column>
        <zy-scroll-table-column
          label="整改状态"
          prop="statusStr"
          width="80px"
        ></zy-scroll-table-column>
      </zy-scroll-table>
      <zy-build-floor
        ref="zyBuildFloor"
        :listTable="listPage"
        :title="dailogType == 'measure' ? '实测实量' : dailogType == 'locale' ? '现场验收' : '检查问题'"
        @select="setItem"
      >
        <template v-if="dailogType == 'measure'">
          <el-table-column
            label="部位"
            prop="yslc"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="实测实量项"
            prop="measureItemName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="类型"
            prop="checkObjStr"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="合格率"
            prop="passRateStr"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="实测时间"
            prop="createTime"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="实测人"
            prop="userName"
            show-overflow-tooltip
          ></el-table-column>
        </template>
        <template v-else-if="dailogType == 'locale'">
          <el-table-column
            label="部位"
            prop="positionStr"
            minWidth="90"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="工序验收项"
            prop="processAcceptanceItemName"
            minWidth="192"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="报验日期"
            prop="createTime"
            align="center"
            minWidth="100"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="实际验收日期"
            prop="endTime"
            align="center"
            minWidth="110"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="验收状态"
            prop="status"
            minWidth="100"
            show-overflow-tooltip
          >
            <template slot-scope="{ row }">
              <span
                v-if="row.status == '验收不通过'"
                style="color: #fc4a66"
                >{{ row.status }}</span
              >
              <span
                v-else-if="row.status == '待验收'"
                style="color: #ffb822"
                >{{ row.status }}</span
              >
              <span
                v-else
                style="color: #63e881"
                >{{ row.status }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            label="申报人"
            prop="applicant"
            minWidth="80"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="验收人"
            prop="acceptancePersonnel"
            minWidth="80"
            show-overflow-tooltip
          ></el-table-column>
        </template>
        <template v-else-if="dailogType == 'danger'">
          <el-table-column
            label="部位"
            prop="position"
            minWidth="90"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="检查项"
            prop="checkitemname"
            minWidth="100"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="问题等级"
            prop="problemlevel"
            minWidth="80"
            show-overflow-tooltip
          >
            <template slot-scope="{ row }">
              <span
                v-if="row.problemlevel == '一般'"
                style="color: #63e881"
                >{{ row.problemlevel }}</span
              >
              <span
                v-else
                style="color: #fc4a66"
                >{{ row.problemlevel }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            label="问题描述"
            prop="problemdescription"
            minWidth="202"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="整改状态"
            prop="statusStr"
            minWidth="90"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="整改日期"
            prop="rectificationdate"
            minWidth="110"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="整改人"
            prop="rectificationname"
            minWidth="80"
            show-overflow-tooltip
          ></el-table-column>
        </template>
        <template #page>
          <el-pagination
            class="pagination"
            background
            layout="->,total, sizes, prev, pager, next,jumper"
            :total="page.total"
            :page-size.sync="page.pageSize"
            :current-page.sync="page.pageNum"
            :page-sizes="[10, 20, 30, 40]"
            @size-change="getList"
            @current-change="getList"
          >
          </el-pagination>
        </template>
      </zy-build-floor>
    </zy-layout-column>
  </zy-layout>
</template>

<script>
import components from "@/view/jxyh/components"
import Polling from "./components/polling.vue"
import Measure from "./components/measure.vue"
import { getQualityHighLight, getcheckProblem, getSiteAcceptance, getMeasureList, getcheckProblemPage, getSiteAcceptancePage } from "@/jxyh/api/quality"
export default {
  ...components(["ZyLayout", "ZyLayoutColumn", "ZyBuildFloor", "ZyTitle", "ZyTable", "ZyScrollTable", "ZyScrollTableColumn", "ZyChartRing", "ZyImageText", "ZyDialog", "ZyBuild", "ZyImage"], {
    Polling,
    Measure,
  }),
  data() {
    return {
      localeList: [],
      dangerList: [],
      pollingList: [
        {
          inspectionBatchName: "2024年第二季度质量检查",
          checkTypeStr: "集团巡检",
          principalName: "王泽鹏,刘佳裕",
          createTime: "2024-06-19",
        },
        {
          inspectionBatchName: "2024年第二季度质量检查",
          checkTypeStr: "集团巡检",
          principalName: "王泽鹏,刘佳裕",
          createTime: "2024-06-01",
        },
        {
          inspectionBatchName: "2024年第二季度质量检查",
          checkTypeStr: "集团巡检",
          principalName: "王泽鹏,刘佳裕",
          createTime: "2024-05-30",
        },
        {
          inspectionBatchName: "2024年第二季度质量检查",
          checkTypeStr: "集团巡检",
          principalName: "王泽鹏,刘佳裕",
          createTime: "2024-05-16",
        },
        {
          inspectionBatchName: "2024年第二季度质量检查",
          checkTypeStr: "集团巡检",
          principalName: "王泽鹏,刘佳裕",
          createTime: "2024-05-10",
        },
      ],
      highLightList: [],
      color: ["#007FF4", "#FC4A66", "#FFB822", "#62FF85", "#19FBFF"],
      sgList: [
        {
          value: 190,
          bottom: "巡检总数",
          pieList: [
            {
              name: "已闭合",
              value: 190,
              rate: "100%",
            },
            {
              name: "未闭合",
              value: 0,
              rate: "0%",
            },
          ],
        },
        {
          value: 2,
          bottom: "本周巡检",
          pieList: [
            {
              name: "已闭合",
              value: 75,
              rate: "75%",
            },
            {
              name: "未闭合",
              value: 25,
              rate: "25%",
            },
          ],
        },
      ],
      measureList: [],
      listPage: [],
      dailogType: "",
      page: {
        total: 0,
        pageSize: 10,
        pageNum: 1,
      },
      currentItem: {},
    }
  },
  mounted() {
    this.getQualityList()
  },
  methods: {
    getQualityList() {
      getQualityHighLight({ viewEnum: 6 }).then((res) => {
        this.highLightList = res.data || []
      })
      getcheckProblem().then((res) => {
        this.dangerList = res.data || []
      })
      getSiteAcceptance().then((res) => {
        this.localeList = res.data || []
      })
      getMeasureList({ customPageNum: 1, customPageSize: 4 }).then((res) => {
        this.measureList = res.data?.page?.list || []
      })
    },

    showDialog(type) {
      this.dailogType = type
      this.$refs.zyBuildFloor.open()
    },
    setItem(item) {
      this.currentItem = item
      this.getList()
    },
    getList() {
      let params = {
        buildingId: this.currentItem.buildingId,
        customPageNum: this.page.pageNum,
        customPageSize: this.page.pageSize,
      }
      if (this.dailogType == "measure") {
        params.floorId = this.currentItem.floorId
        getMeasureList(params).then((res) => {
          this.listPage = res.data.page.list
          this.$set(this.page, "total", res.data.page.total)
        })
      } else if (this.dailogType == "danger") {
        params.floorId = this.currentItem.floorId
        getcheckProblemPage(params).then((res) => {
          this.listPage = res.data.page.list
          this.$set(this.page, "total", res.data.page.total)
        })
      } else {
        params.position = this.currentItem.yslc
        getSiteAcceptancePage(params).then((res) => {
          this.listPage = res.data.page.list
          this.$set(this.page, "total", res.data.page.total)
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.zy-layout-quality {
  color: #fff;
  .file-img {
    width: 88px;
    height: 50px;
    .image-slot {
      line-height: 50px;
    }
  }
  .auto-height {
    margin-top: 10px;
    height: calc(100% - 84px) !important;
    /deep/ .recordlist {
      height: calc(100% - 40px) !important;
    }
  }
}
/deep/ .zy-table::before {
  background-color: #ffffff20;
}
.pagination.el-pagination {
  margin: 20px 0;
  background-color: transparent !important;
  /deep/ .el-input-group__prepend {
    font-family: SourceHanSansCN, SourceHanSansCN;
    background-color: transparent !important;
    border: 1px solid #66bbf9;
    border-right: none;
    color: #ffffff;
  }
  /deep/ .el-input__inner {
    background-color: transparent !important;
    border: 1px solid #66bbf9;
    color: #66bbf9;
    &::placeholder,
    &::-webkit-input-placeholder,
    &:-moz-placeholder,
    &:-ms-input-placeholder {
      color: #66bbf990;
    }
  }
  /deep/ .el-select .el-input .el-select__caret {
    color: #66bbf9;
  }
  /deep/ .el-pagination__total {
    color: #66bbf9 !important;
    line-height: 28px;
  }
  /deep/ .el-pagination__jump {
    color: #66bbf9 !important;
    .el-input__inner {
      color: #ffffff;
    }
  }
}
.pagination.el-pagination.is-background {
  font-family: SourceHanSansCN, SourceHanSansCN;
  /deep/ .btn-prev,
  /deep/ .btn-next {
    background: transparent;
    color: #ffffff;
    border: 1px solid #66bbf9;
  }
  /deep/ .el-pager li {
    color: #ffffff;
    background: transparent;
    border: 1px solid #66bbf9;
    &:not(.disabled).active {
      background: #0360f5;
      border: 0;
    }
  }
}
/deep/ .zy-dialog-body {
  padding-bottom: 0px !important;
}
</style>
