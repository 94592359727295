const maps = {
  ZyLayout: "./zy-layout/index.vue",
  ZyLayoutColumn: "./zy-layout/column.vue",
  ZyTitle: "./zy-title/index.vue",
  ZySelect: "./zy-select/index.vue",
  ZyScrollTable: "./zy-scroll-table/index.vue",
  ZyScrollTableColumn: "./zy-scroll-table/column.vue",
  ZyChartBar: "./zy-chart-bar/index.vue",
  ZyChartRing: "./zy-chart-ring/index.vue",
  ZyCanvasRing: "./zy-canvas-ring/index.vue",
  ZyPieTable: "./zy-pie-table/index.vue",
  ZyThree: "./zy-three/index.vue",
  ZyCarouselBox: "./zy-carousel-box/index.vue",
  ZyMarks: "./zy-marks/index.vue",
  ZyMonitor: "./zy-monitor/index.vue",
  ZyMultiplePie: "./zy-multiple-pie/index.vue",
  ZyDateSelect: "./zy-date-select/index.vue",
  ZyImageText: "./zy-image-text/index.vue",
  ZyDialog: "./zy-dialog/index.vue",
  ZyTable: "./zy-table/index.vue",
  ZyImgGif: "./zy-img-gif/index.vue",
  ZyBuild: "./zy-build/index.vue",
  ZyBuildFloor: "./zy-build-floor/index.vue",
  ZyImage: "./zy-image/index.vue",
  ZyGant: "./zy-gant/index.vue",
  ZyGantPlus: "./zy-gant-plus/index.vue",
}
export default function (keys, cps) {
  const components = {}
  keys.forEach((key) => {
    const component = maps[key]
    if (component) {
      components[key] = () => ({
        component: import(`${component}`),
      })
    } else {
      console.error(`【${key}】组件不存在或未注册，请检查组件名是否正确`)
    }
  })
  return {
    components: {
      ...components,
      ...cps,
    },
  }
}
