<template>
  <ul class="message">
    <li class="message-box" v-for="(item, index) in list" :key="index" @click="openNotice(item)">
      <div class="message-box-left"></div>
      <div class="message-box-right">
        <div class="message-box-right-text">
          <!-- <el-image class="img" :src="item.imgUrl"></el-image> -->
          <div class="jxyh-flex-h" style="width: 100%">
            <div class="jxyh-flex-v space-between">
              <span class="overText">{{ `${item.buildingName}${item.floorName}-${item.measureItemName}` }}</span>
              <span>{{ item.createTime }}</span>
            </div>
            <div class="jxyh-flex-v space-between">
              <span style="padding-left: 20px;">{{ item.burst }}/{{ item.total }}</span>
              <el-progress style="width: 78%" :percentage="Number(item.passRateStr)"></el-progress>
            </div>
          </div>
        </div>
      </div>
    </li>
    <div class="jxyh-flex-v" style="width: 100%;height: 300px;">暂无数据</div>
  </ul>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="less" scoped>
.message {
    height: 322px;
    overflow: hidden;
  .message-box {
    display: flex;
    margin-top: 10px;
    cursor: pointer;
    &-left {
      width: 4px;
      height: 70px;
      background: #19fbff;
    }
    &-right {
      width: 531px;
      height: 70px;
      padding: 10px 0;
      background: linear-gradient(277deg, rgba(16, 31, 68, 0) 0%, rgba(46, 205, 235, 0.2) 100%);
      &-text {
        display: flex;
        font-size: 14px;
        line-height: 26px;
        margin: 0 16px 0 0;
        /deep/ .el-progress-bar{
            margin-right: -95px;
        }
        /deep/ .el-progress-bar__outer {
          background-color: #ffffff20 !important;
          border-radius: 5px;
        }
        /deep/ .el-progress-bar__inner {
          background: linear-gradient(90deg, #165ba8 0%, #00c3f4 100%);
          &::after {
            width: 6px;
            position: absolute;
            right: 0;
            top: 0;
            border-radius: 50%;
            background-color: white;
          }
        }
        /deep/ .el-progress__text {
          width: 55px;
          text-align: right;
          color: #b1b1b1 !important;
          margin-left: 40px;
        }
        .space-between {
          width: 100%;
          justify-content: space-between;
        }
        .img {
          width: 108px;
          height: 50px;
          margin: 10px;
        }
        .overText {
          flex: 1;
          display: inline-block;
          text-overflow: ellipsis;
          overflow: hidden;
          line-clamp: 1;
          white-space: nowrap;
          margin-left: 20px;
        }
      }
    }
  }
}
</style>