import { get, post } from "@/utils/request"

// 获取质量亮点
export function getQualityHighLight(params) {
  return get("/pms/content/list", params)
}

// 获取内容详情
export function getContentDetail(params) {
  return get("/pms/content/detail", params)
}

// 获取检查问题
export function getcheckProblem() {
  return get("/pms/checkProblem/list")
}

// 获取检查问题分页列表
export function getcheckProblemPage(params) {
  return get("/pms/checkProblem/page", params)
}

// 获取现场验收
export function getSiteAcceptance() {
  return get("/pms/checkProblem/siteAcceptance")
}

// 获取现场验收分页列表
export function getSiteAcceptancePage(params) {
  return get("/pms/checkProblem/siteAcceptance/page", params)
}

// 获取实测实量
export function getMeasureList(params) {
  return get("/pms/view/quality/measurement", params)
}

// 获取楼栋
export function getBuildList() {
  return get("/pms/view/quality/building")
}

// 获取楼层
export function getFloorList(params) {
  return get("/pms/view/quality/measurementMore", params)
}
